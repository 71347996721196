<template>
  <div class="adx-settleMent-container">
    <div class="adx-settleMent-wrapper">
      <div class="wrapper-item">
        <div class="adx-wrapper-left">
          <div class="left-item" v-for="(item, index) in aggregationList" :key="index">
            <div class="left-item-box">
              <div class="item-head">
                <div class="item-icon"><img :src="item.iconUrl" class="head-icon" /></div>
                <h1 class="title">{{ item.title }}</h1>
              </div>
              <div class="desc">
                <span>{{ item.desc }}</span>
              </div>
            </div>
            <div class="left-item-line">
              <img v-if="item.hasLine" src="../../../../assets/images/hasLinepng.png" alt="" />
            </div>
          </div>
        </div>
        <div class="adx-wrapper-right">
          <a-button class="modalButton" type="primary" @click="noteVisible = true">发票说明</a-button>
        </div>
      </div>
    </div>
    <a-modal v-model="noteVisible" :footer="null" title="发票说明" :width="810">
      <div class="modal-wrapper">
        <div class="adxSettleModal">
          <div class="item-top">
            <div class="top-left">
              <img class="top-left-img" src="../../../../assets/images/open.png" alt="" srcset="" />
              <span class="top-left-label">开票信息</span>
            </div>
            <div style="padding-top: 10px" class="top-right">
              <a-button type="primary" @click="allCopy">一键复制</a-button>
            </div>
          </div>
          <div class="item-part">
            <div class="item-bar" v-for="(item, index) in noteList" :key="index + 1">
              <div class="item-box">
                <span class="itemDesc">{{ item.desc }}</span>
                <span class="itemContent">{{ item.content }}</span>
              </div>
            </div>
          </div>
          <div class="item-top">
            <div class="top-left">
              <img class="top-left-img" src="../../../../assets/images/post.png" alt="" srcset="" />
              <span class="top-left-label">邮寄地址</span>
            </div>
          </div>
          <div class="shortBar">
            <div class="item-bar" v-for="(item, index) in addressList" :key="index + 2">
              <div class="item-box">
                <span class="itemDesc" style="text-align: right">{{ item.desc }}</span>
                <span class="itemContent">{{ item.content }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
  <!-- 结算说明 -->
  <!-- 弹窗 -->
</template>

<script>
import { copy } from '@/utils/string'
export default {
  name: 'ADXSettlementNav',
  components: {},
  data () {
    return {
      noteVisible: false,
      aggregationList: [
        {
          title: '申请结算',
          desc: ' 申请结算并下载核对结算单',
          iconUrl: require('@/assets/images/firstPost.png'),
          hasLine: true
        },
        {
          title: '正确开具发票',
          desc: '选择线下邮寄/线上提交电子发票',
          hasLine: true,
          iconUrl: require('@/assets/images/secondPost.png')
        },

        {
          title: '发票审核',
          desc: '财务审核发票金额与结算金额一致',
          hasLine: true,
          iconUrl: require('@/assets/images/thirdPost.png')
        },
        {
          title: '支付结算金额',
          desc: '发票审核通过后，财务支付结算金额',
          hasLine: false,
          iconUrl: require('@/assets/images/fourthPost.png')
        }
      ],
      noteList: [
        {
          desc: '公司名称:',
          content: '北京前呈无限科技有限公司'
        },
        {
          desc: '公司税号:',
          content: '91110108MA01RPH1X0'
        },
        {
          desc: '注册地址:',
          content: '北京市朝阳区光华路22号10层2单元1108'
        },
        {
          desc: '开户银行:',
          content: '招商银行股份有限公司北京东城支行'
        },
        {
          desc: '发票类型:',
          content: '6%增值税专用发票'
        },
        {
          desc: '联系电话:',
          content: '18310119242'
        },
        {
          desc: '发票内容:',
          content: '信息服务费'
        }
      ],
      addressList: [
        {
          desc: '公司地址:',
          content: '北京市朝阳区光华路\n' + 'soho一期二单元1108'
        },
        {
          desc: '收件人:',
          content: '前呈无限'
        },
        {
          desc: '电话:',
          content: '18334688997'
        }
      ]
    }
  },
  methods: {
    // 一键复制
    allCopy () {
      const noteString = '开票信息\n' + this.noteList.map((item) => `${item.desc} ${item.content}`).join('\n')
      const addressString = '邮寄地址\n' + this.addressList.map((item) => `${item.desc} ${item.content}`).join('\n')
      const combinedString = `${noteString}\n\n${addressString}`
      this.copyAll(combinedString)
    },
    copyAll (params) {
      const result = copy(params)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.adx-settleMent-container {
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  .adx-settleMent-wrapper {
    background-color: #f2f6fd;
  }
  .wrapper-item {
    display: flex;
    justify-content: space-between;
    .adx-wrapper-left {
      display: flex;
      .left-item {
        display: flex;
        justify-content: flex-start;
        .left-item-box {
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          padding: 25px 30px;
          height: 100px;
          width: 100%;
          .item-head {
            width: 80%;
            height: 30px;
            display: flex;
            .head-icon {
              width: 18px;
              height: 20px;
              color: blue;
            }
          }
          .title {
            margin: 5px 0 0 5px;
            width: 100px;
            font-size: 14px;
            font-weight: 600;
            color: @primary-color;
            line-height: 17px;
          }
          .desc {
            font-size: 12px;
            font-weight: 400;
            color: #969aa0;
            line-height: 15px;
          }
        }
        .left-item-line {
          img {
            width: 70px;
            margin: 40px 0 0 0;
          }
        }
      }
    }
    .adx-wrapper-right {
      padding: 30px 50px 0 0;
    }
  }
}
.modal-wrapper {
  padding: 20px;
}
.adxSettleModal {
  height: 500px;
  background-color: #f9f9f9;
  .item-top {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px;
    .top-left {
      margin-top: 20px;
      height: 100%;
      width: 200px;
      .top-left-img {
        width: 10%;
        height: 60%;
        margin: 0 5px 6px 0;
      }
      .top-left-label {
        color: #333333;
        font-size: 16px;
        margin: 0 0 0 5px;
      }
    }
  }
}
.item-part {
  margin: -5px 10px 0 10px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  .item-bar {
    width: 320px;
    margin: 10px 10px;
    color: #4b4b4b;
    .item-box {
      font-size: 14px;
      padding: 5px 5px;
      .itemDesc {
        width: 80px;
      }
      .itemContent {
        padding: 0 10px;
      }
    }
  }
}
.shortBar {
  margin: -10px 10px 0 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .item-bar {
    width: 250px;
    margin: 10px 10px;
    color: #4b4b4b;
    .item-box {
      font-size: 14px;
      width: 200px;
      .itemDesc {
        width: 73px;
      }
      .itemContent {
        padding: 0 5px;
      }
    }
  }
}
</style>
